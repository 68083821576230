


























































import { Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import { formatTimestamp } from '@/utils/utils'
@Component({ name: 'License' })
export default class LogManage extends VueBase {
  license = ''
  // machinecode = ''
  centerDialogVisible = false
  authenticated = false
  detail = {
    end_time: '',
    max_concurrency: 0,
    current_concurrency: 0,
    start_time: '',
    target: '',
  }
  closeLicense() {
    this.license = ''
    this.centerDialogVisible = false
  }
  async enterLicense() {
    await this.uploadLicense()
    this.closeLicense()
  }
  // async getMachinecode() {
  //   this.loadingStart()
  //   const res = await this.services.setting.machinecode()
  //   this.loadingDone()
  //   if (res.status === 201) {
  //     this.machinecode = res.data
  //   } else {
  //     this.$message.error(res.msg)
  //   }
  // }

  async uploadLicense() {
    this.loadingStart()
    const res = await this.services.setting.uploadLicense({
      license: this.license,
    })
    this.loadingDone()
    if (res.status === 201) {
      this.$message.success(res.msg)
      window.location.reload()
    } else {
      this.$message.error(res.msg)
    }
  }

  private onCopy() {
    this.$message({
      message: '已复制',
      type: 'success',
    })
  }

  private onError() {
    this.$message({
      message: '复制失败！',
      type: 'error',
    })
  }
  async isAuthenticated() {
    const res = await this.services.setting.isAuthenticated()
    if (res.status === 201) {
      this.authenticated = res.data
    } else {
      this.$message.error(res.msg)
    }
  }

  async detailLicense() {
    const res = await this.services.setting.detailLicense()
    if (res.status === 201) {
      this.detail.target = res.data.target
      this.detail.max_concurrency = res.data.max_concurrency
      this.detail.end_time = formatTimestamp(res.data.end_time)
      this.detail.start_time = formatTimestamp(res.data.start_time)
    } else {
      this.$message.error(res.msg)
    }
  }

  async currentConcurrency() {
    const res = await this.services.setting.currentConcurrency()
    if (res.status === 201) {
      this.detail.current_concurrency = res.data
    } else {
      this.$message.error(res.msg)
    }
  }

  private timer: any = null

  async created() {
    await this.isAuthenticated()
    // this.getMachinecode()
    if (this.authenticated) {
      await this.detailLicense()
      await this.currentConcurrency()
      this.timer = setInterval(() => {
        this.currentConcurrency()
      }, 5000)
    }
  }

  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  }
}
